.input-wrapper {
  margin-bottom: 1.5rem;
  position: relative;
  text-align: left;
  font-family: $font-regular;
  .eye-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    cursor: pointer;
    color: #3559fc;
  }
  span {
    color: red;
    font-size: 0.75rem;
  }

  label {
    font-size: 0.75rem;
    font-family: $font-regular;
  }
}

.text-input {
  padding: 0.5rem;
  border: 1px solid transparent;
  width: 100%;
  border-radius: 6px;
  font-size: 0.75rem;
  font-family: $font-regular;
  background-color: #eff2f5;
  box-shadow: none !important;
  &.error {
    border: 1px solid red !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.primary-btn {
  min-width: 135px;
  font-size: 1rem;
  font-family: $font-regular;
  border-radius: 8px;
  text-transform: uppercase;
  color: $color-white !important;
  background-color: $color-black !important;
  &.lg {
    width: 100%;
  }
  &.round {
    border-radius: 20px;
  }
  &.clr-transp {
    background-color: transparent !important;
    color: $color-gray !important;
    border: 1px solid color-gray;
  }
  &.clr-blue {
    background-color: #3559fc !important;
    color: #fff !important;
  }
  &:focus,
  &:hover {
    box-shadow: 1px 1px 10px 3px #bfbfbf;
  }
}

.loader {
  display: flex;
  font-size: 0.75rem;
  text-align: center;
  color: #19233c;
}

.dotOne {
  animation: visibilityOne 2s linear infinite;
}
@keyframes visibilityOne {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotTwo {
  animation: visibilityTwo 2s linear infinite;
}
@keyframes visibilityTwo {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotThree {
  animation: visibilityThree 2s linear infinite;
}
@keyframes visibilityThree {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.empty-state {
  min-width: 40%;
  align-self: center;
  margin-inline: auto;
  height: 50%;
  background-color: rgba(53, 89, 252, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-regular;
  font-size: 0.75rem;

  &.in-frame {
    min-width: 100%;
    margin-block: auto;
    height: 250px;
  }
}

.otp-field-container {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: $font-bold;
    font-size: 2rem;
  }
}

.otp-field {
  width: 40px;
  height: 45px;
  border-radius: 5px;
  border-color: $color-black !important;
  box-shadow: none !important;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.error {
    border-color: $color-error !important;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
}
.loader-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: inherit;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.loader-svg {
  font-size: 2rem;
  color: #37e637;
  animation: color-fill 1s ease-in-out;
}

.custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.custom-modal {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  .custom-modal-copy {
    color: #4a4a4a;
    font-family: $font-extra-light;
    font-size: 1.5rem;
    letter-spacing: 1.2px;
  }

  .custom-modal-cta {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 15px;
  }
}

* {
  padding: 0;
  margin: 0;
}

.completed-state {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .completed-state-copy {
    font-family: $font-regular;
    font-size: 1.4rem;
    text-align: center;
  }
  svg {
    margin-bottom: 55px;
    width: 150px;
    height: 150px;
  }
}
