@font-face {
  font-family: "Inter Regular";
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Bold";
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Extra Light";
  font-weight: 200;
  font-style: normal;
  font-display: block;
  src: url("./fonts/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Light";
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url("./fonts/Inter-Light.ttf") format("truetype");
}
