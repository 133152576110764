.form-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-height: 600px;
  width: 400px;
  border-radius: 19px;
  background: #fff;
  box-shadow: 4px 9px 43px 13px rgba(68, 68, 68, 0.33);
  position: relative;
  overflow: hidden;
  .close-frame {
    position: absolute;
    top: 20px;
    right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.form-heading {
  text-align: center;
  font-family: $font-bold;
  .form-icon {
    width: 70px;
    height: 70px;
    background-color: lightblue;
    border-radius: 35px;
    margin-inline: auto;
  }
  .sub-text {
    color: $color-gray;
    font-size: 0.75rem;
    font-family: $font-regular;
    margin-top: 10px;
    b {
      color: $color-black;
    }
  }
  h1 {
    font-size: 1rem;
  }
  h2 {
    margin: 0 !important;
    font-size: 1rem;
  }
}

.form-detail {
  margin-top: 20px;
}

.form-body {
  font-family: $font-regular;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .description-text {
    font-size: 0.8rem;
    margin-top: 20px;
  }
}

.light-text {
  color: grey;
}

.consent-for-desc {
  font-size: 1rem;
  font-family: $font-bold;
  margin-top: 5px;

  flex: 1;
  display: flex;
  flex-direction: column;
  .consent-desc {
    font-family: $font-bold;
    font-size: 1.2rem;
    letter-spacing: 1.18px;
  }
  .asset-desc {
    font-family: $font-regular;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    flex: 1;
    justify-content: center;
    margin-top: 8px;
    overflow: hidden;
    word-break: break-word;
    margin-block: 60px;
    .asset-name {
      font-family: $font-bold;
      font-size: 1rem;
    }
  }
}

.parties-wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 25px;
}
.consent-parties {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 48%;
  word-wrap: break-word;
}

.text-bold {
  font-family: $font-bold;
  font-size: 0.75rem;
}

.text-norm {
  font-family: $font-regular;
  font-size: 0.75rem;
  sup {
    color: $color-black;
    font-family: $font-bold;
  }
}

.form-cta {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  .msg {
    position: absolute;
    width: 100%;
    top: -30px;
    font-family: $font-light;
    font-size: 0.75rem;
    &.err {
      color: red;
    }
    &.success {
      color: green;
    }
  }
}
.form-msg {
  margin-block: 30px;
}

.nft-item {
  height: 230px;
  width: 400px;
  position: relative;
  margin-top: 20px;
  right: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px -3px 8px rgba(0, 0, 0, 0.12);
  .item {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 230px;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .full-width {
    img {
      width: 100% !important;
      object-fit: initial !important;
    }
  }
  .name {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
