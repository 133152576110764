//colors
$color-white: #fff;
$color-light-gray: #eff2f5;
$color-mid-gray: #848484;
$color-dark-gray: #878787;
$color-gray: #aaaaaa;
$color-dark: #182550;
$color-error: #ff0000;
$color-black: #000;
$color-blue: #3559fc;

//fonts
$font-regular: "Inter Regular";
$font-bold: "Inter Bold";
$font-light: "Inter Light";
$font-extra-light: "Inter Extra Light";
