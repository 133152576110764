@import "./variables.scss";
@import "./forms.scss";
@import "./typography";
@import "./common";
@import "./animated.scss";

html {
  font-size: 18px;
  height: 100%;
  background: transparent;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, $font-regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clr-grn {
  color: green !important;
}

.not-found {
  font-family: $font-bold;
  text-align: center;
  b {
    font-size: 3rem;
    color: red;
  }
  a {
    float: left;
    padding-top: 10px;
    font-size: 0.7rem;
    padding-left: 10px;
  }
}

.auth-modal {
  height: 600px;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .auth-modal-title {
    font-size: 1rem;
    font-family: $font-bold;
    text-align: center;
    margin-top: 60px;
    span {
      font-size: 0.75rem;
    }
  }
  .auth-copy {
    font-family: $font-extra-light;
    font-size: 1.5rem;
    line-height: 31px;
    letter-spacing: -0.02em;
    text-align: center;
    .sub {
      margin-top: 60px;
    }
  }
  svg {
    align-self: center;
    margin-top: 20px;
  }
  .auth-modal-footer {
    font-size: 0.8rem;
    color: $color-gray;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.04em;
    font-family: $font-extra-light;
    b {
      font-family: $font-bold;
    }
  }
  .auth-err {
    font-size: 0.8rem;
    color: red;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.04em;
    font-family: $font-light;
  }
}

.secured-by-footer {
  font-size: 0.8rem;
  color: $color-gray;
  text-align: center;
  margin-top: 20px;
  letter-spacing: 0.04em;
  font-family: $font-light;
  b {
    font-family: $font-bold;
  }
}
